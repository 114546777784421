<template>
	<div class="text-center">
		<v-dialog :value="active" width="300" :type="success ? 'success' : 'error'" @input="close">
			<v-card rounded="xl">
				<v-sheet :color="dialogColor">
					<v-card-title class="headline">
						{{ $t('search.feedback') }}
					</v-card-title>
					<v-card-text style="white-space: pre-line">
						{{ message }}
					</v-card-text>

					<v-card-actions>
						<v-spacer />
						<v-btn color="primary" text @click="close">
							{{ $t('search.ok') }}
						</v-btn>
					</v-card-actions>
				</v-sheet>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'FeedbackDialog',
	computed: {
		dialogColor() {
			return this.success ? 'green' : 'red'
		},
		...mapGetters({
			active: 'dialog/active',
			success: 'dialog/success',
			message: 'dialog/message'
		})
	},
	methods: {
		...mapActions('dialog', ['close'])
	}
}
</script>
